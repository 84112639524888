import React from "react";

const ContactText = () => {
  return (
    <div className="w-full flex text-white">
      <div className="mx-auto">
        <p className=" text-4xl sm:text-5xl py-3 font-sa">Stay Connected</p>
        <p className="text-lg  opacity-80" id="contact">
          Submit the form below to get in touch
        </p>
      </div>
    </div>
  );
};

export default ContactText;
